import React from "react";
import * as styles from "./DemoCeo.module.scss";
import Logo from "../../../svg/mwlogo.svg";
function DemoCeo(){
    return(
        <div className={styles.container}>

            <div className={styles.image}>
            </div>
            <div className={styles.content}>
                <Logo />
                <h1>From the Creative Director </h1>
                <p><b>You can take control of your virtual presence.</b> <br/><br/> With a deep passion for creative workspace tools, we have a playful and innovative take on how you present yourself digitally. Customize and present material that matters to you effectively, creatively, and boldly. We have taken a comprehensive approach covering all types of needs and ambition levels to boost your communications.</p>
                <br/><br/>
                <p className={styles.ceotext}>CEO and Creative Director,</p>
                <p className={`${styles.ceotext} ${styles.name}`}>Jone Smedsvig </p>
            </div>
        </div>
    );
}
export default DemoCeo;